<template>
	<div class="login-body">
		<div class="login-wrapper">
			<div class="login-panel">
				<router-link to="/" class="logo">
					<img src="layout/images/logo-poseidon-dark.svg" alt="poseidon-layout" />
				</router-link>
				<div class="login-form">
					<h2>Login To Your Premium Account</h2>
					<p>You don't have an account? <router-link to="/"> Sign up</router-link></p>
					<InputText type="text" autocomplete="off" placeholder="Email" />
					<InputText type="password" autocomplete="off" placeholder="Password" />
					<Button label="LOGIN" type="button"></Button>
				</div>

				<p>A problem? <router-link to="/">Click here</router-link> and let us help you.</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {};
</script>
